'use client';

import { json, type LoaderFunctionArgs } from '@remix-run/node';
import { Link, useLoaderData, useMatches } from '@remix-run/react';
import { useEffect, useRef, type FC } from 'react';

import { getUser } from '#app/utils/auth.server';
import { ROLES } from '#app/utils/roles';
import { userHasRole } from '#app/utils/user';
// import { SearchBar } from '../search-bar';
import { DesktopNavigation } from './DesktopNavigation';

const clamp = (number: number, a: number, b: number) => {
  let min = Math.min(a, b);
  let max = Math.max(a, b);
  return Math.min(Math.max(number, min), max);
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getUser(request);
  return json({ user });
}

export const Header: FC = () => {
  const data = useLoaderData<typeof loader>();
  const user = data.user;

  const isLoggedIn = !!user;
  const isAdmin = userHasRole(user, ROLES.ADMIN);

  const matches = useMatches();
  const isHomePage = matches.find(m => m.id === 'routes/users+/index');
  const headerRef = useRef<React.ElementRef<'div'>>(null);
  const avatarRef = useRef<React.ElementRef<'div'>>(null);
  const isInitial = useRef(true);

  useEffect(() => {
    let downDelay = avatarRef.current?.offsetTop ?? 0;
    let upDelay = 64;

    const setProperty = (property: string, value: string) =>
      document.documentElement.style.setProperty(property, value);

    const removeProperty = (property: string) =>
      document.documentElement.style.removeProperty(property);

    const updateHeaderStyles = () => {
      if (!headerRef.current) {
        return;
      }

      let { top, height } = headerRef.current.getBoundingClientRect();
      let scrollY = clamp(
        window.scrollY,
        0,
        document.body.scrollHeight - window.innerHeight,
      );

      if (isInitial.current) {
        setProperty('--header-position', 'sticky');
      }

      setProperty('--content-offset', `${downDelay}px`);

      if (isInitial.current || scrollY < downDelay) {
        setProperty('--header-height', `${downDelay + height}px`);
        setProperty('--header-mb', `${-downDelay}px`);
      } else if (top + height < -upDelay) {
        let offset = Math.max(height, scrollY - upDelay);
        setProperty('--header-height', `${offset}px`);
        setProperty('--header-mb', `${height - offset}px`);
      } else if (top === 0) {
        setProperty('--header-height', `${scrollY + height}px`);
        setProperty('--header-mb', `${-scrollY}px`);
      }

      if (top === 0 && scrollY > 0 && scrollY >= downDelay) {
        setProperty('--header-inner-position', 'fixed');
        removeProperty('--header-top');
        removeProperty('--avatar-top');
      } else {
        removeProperty('--header-inner-position');
        setProperty('--header-top', '0px');
        setProperty('--avatar-top', '0px');
      }
    };

    const updateStyles = () => {
      updateHeaderStyles();
      isInitial.current = false;
    };

    updateStyles();
    window.addEventListener('scroll', updateStyles, { passive: true });
    window.addEventListener('resize', updateStyles);

    return () => {
      window.removeEventListener('scroll', updateStyles);
      window.removeEventListener('resize', updateStyles);
    };
  }, [isHomePage]);

  // const isOnSearchPage = matches.find(m => m.id === 'routes/users+/index');

  // const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />;

  return (
    <>
      <header
        className="p border-primary-800/20 bg-primary-500 relative z-50 flex flex-none flex-col
          border-b-2"
        style={{
          height: 'var(--header-height)',
          marginBottom: 'var(--header-mb)',
        }}
      >
        <div
          ref={headerRef}
          className="= top-0 z-10 pt-0"
          style={{
            position:
              'var(--header-position)' as React.CSSProperties['position'],
          }}
        >
          <div className="relative mx-10 flex gap-4">
            <div className="flex flex-1">
              <Link to="/" aria-label="Home" className={'pointer-events-auto'}>
                <h1 className="text-secondary-50 mt-1 font-serif text-[3rem] ">
                  RHSR
                </h1>
              </Link>
            </div>

            <div className="flex flex-1 items-center justify-end">
              <DesktopNavigation
                className="pointer-events-auto hidden md:block"
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
