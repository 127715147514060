import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
} from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import { EpicProgress } from '#app/components/progress-bar';
import { useTheme } from '#app/components/theme-switcher';
import { Footer } from '#app/components/ui/Footer';
import { Header } from '#app/components/ui/Header';
import { EpicToaster } from '#app/components/ui/sonner';
import { getUserId, logout } from '#app/utils/auth.server';
import { getHints } from '#app/utils/client-hints';
import { prisma } from '#app/utils/db.server';
import { getEnv } from '#app/utils/env.server';
import { honeypot } from '#app/utils/honeypot.server';
import { combineHeaders, getDomainUrl } from '#app/utils/misc';
import { getTheme } from '#app/utils/theme.server';
import { makeTimings, time } from '#app/utils/timing.server';
import { getToast } from '#app/utils/toast.server';
export const links: LinksFunction = () => {
  return [
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Rozha+One&display=swap',
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root',
  });

  const user = userId
    ? await time(
        () =>
          prisma.user.findUniqueOrThrow({
            select: {
              id: true,
              name: true,
              image: true,
              roles: {
                select: {
                  name: true,
                  permissions: {
                    select: { entity: true, action: true, access: true },
                  },
                },
              },
            },
            where: { id: userId },
          }),
        { timings, type: 'find user', desc: 'find user in root' },
      )
    : null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      ENV: getEnv(),
      toast,
      honeyProps,
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
      ),
    },
  );
}

export default function Layout() {
  const theme = useTheme();

  return (
    <>
      <div className="bg- relative flex h-screen w-full flex-col overflow-auto">
        <Header />
        <div className="flex-1 p-10">
          <Outlet />
        </div>
        <Footer />
      </div>
      <EpicToaster closeButton position="top-center" theme={theme} />
      <EpicProgress />
    </>
  );
}
