import { useLoaderData } from '@remix-run/react';
import { type FC } from 'react';
import ignite from '#app/images/ignite-01.png';
import { type loader } from '#app/root';
import { ThemeSwitch } from '../theme-switcher';

export interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  const { requestInfo } = useLoaderData<typeof loader>();
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-primary-500 text-lg text-neutral-200">
      <div className=" mx-auto p-10">
        <div className="flex gap-16 pb-20">
          <p className="w-1/2">
            Friends of Mount Hope Cemetery
            <br />
            PO Box 18713
            <br />
            Rochester, NY 14618
          </p>
          <p className="w-1/2">(585) 290-7727</p>
        </div>
        <div className="flex flex-grow items-center justify-between border-t-2 border-accent-500 py-8">
          <div>
            <p className="text-sm ">&copy; {currentYear} All rights reserved</p>
          </div>
          <div className="flex gap-4">
            <img
              className="rounded-sm bg-white"
              src={ignite}
              alt="Ignite! Jewish Rochester Community Impact grant"
              width={229}
            />
            <p className="max-w-[600px]">
              This project was made possible by an IGNITE! Jewish Rochester
              Community Impact grant supported by the donors to the Jewish
              Federation of Greater Rochester's annual campaign
            </p>
          </div>
          <div>
            <ThemeSwitch userPreference={requestInfo.userPrefs.theme} />
          </div>
        </div>
      </div>
    </footer>
  );
};
