'use client';
import { invariant } from '@epic-web/invariant';
import { json, type LoaderFunctionArgs } from '@remix-run/node';
import { Form, Link, useLoaderData, useSubmit } from '@remix-run/react';
import { useRef } from 'react';
import { getUser } from '#app/utils/auth.server';
import { getUserImgSrc } from '#app/utils/misc';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from './dropdown-menu';
import { Icon } from './icon';
export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getUser(request);
  return json({ user });
}
export function UserDropdown() {
  const { user } = useLoaderData<typeof loader>();
  invariant(user, 'UserDropdown requires a user to be present');
  const submit = useSubmit();
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Link
          to={`/users/${user.id}`}
          // this is for progressive enhancement
          onClick={e => e.preventDefault()}
          className="flex items-center gap-2 px-4 text-neutral-200"
        >
          <img
            className="h-8 w-8 rounded-full object-cover"
            alt={user.name || 'User Image'}
            src={getUserImgSrc(user.image)}
          />
          <span className="text-body-sm font-bold">
            {user.name ?? 'Anonymous'}
          </span>
        </Link>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent sideOffset={8} align="start">
          <DropdownMenuItem
            asChild
            // this prevents the menu from closing before the form submission is completed
            onSelect={event => {
              event.preventDefault();
              submit(formRef.current);
            }}
          >
            <Form action="/logout" method="POST" ref={formRef}>
              <Icon className="text-body-md" name="exit">
                <button type="submit">Sign Out</button>
              </Icon>
            </Form>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}
