import { json, type LoaderFunctionArgs } from '@remix-run/node';
import { Link, useLoaderData, useMatches } from '@remix-run/react';
import clsx from 'clsx';
import { type FC } from 'react';
import { getUser } from '#app/utils/auth.server';
import { UserDropdown } from './UserDropdown';

interface DesktopNavigationProps extends React.ComponentPropsWithoutRef<'nav'> {
  isLoggedIn: boolean;
  isAdmin: boolean;
}

const NavItem = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const matches = useMatches();
  let isActive = matches.some(match => match.pathname === href);

  return (
    <li>
      <Link
        to={href}
        className={clsx(
          'relative block px-4 py-2 text-lg text-neutral-200 transition',
          isActive ? 'text-neutral-300 dark:text-white' : 'hover:text-white ',
        )}
      >
        {children}
        {isActive && (
          <span
            className="from-primary-500/0 via-primary-500/40 to-primary-500/0 dark:from-primary-400/0
              dark:via-primary-400/40 dark:to-primary-400/0 absolute inset-x-1 -bottom-px h-px
              bg-gradient-to-r"
          />
        )}
      </Link>
    </li>
  );
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getUser(request);
  return json({ user });
}

export const DesktopNavigation: FC<DesktopNavigationProps> = ({
  isLoggedIn,
  isAdmin,
  ...props
}) => {
  const { user } = useLoaderData<typeof loader>();
  return (
    <nav {...props}>
      <ul className="flex px-3 text-sm font-normal ">
        <NavItem href="/">Home</NavItem>
        <NavItem href="/survivors">Survivors</NavItem>
        <NavItem href="/about">About</NavItem>
        {isAdmin && <NavItem href="/admin">Administration</NavItem>}
        {user ? <UserDropdown /> : <NavItem href="/login">Sign In</NavItem>}
      </ul>
    </nav>
  );
};
